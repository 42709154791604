import React from 'react';
import { graphql } from 'gatsby';
import { Col, Row } from 'components/Core/Grid';
import { SectionLayout } from 'components/Sections/SectionLayout';
import CallToActionCards from 'components/Sections/CallToActionCards';
import SidebarV2 from 'components/Sections/Sidebar/SidebarV2';
import StyledRichText from 'components/RichText/StyledRichText';
import WebsiteLayout from 'layouts/websiteLayout';
import SEO, { createSEO } from 'components/Core/SEO';

const PressDetailsTemplate = (props) => {
  const { edges } = props.data.allContentfulPress;
  const { postTitle, content, postDate, id, seo: pressSeo } = props.data.contentfulPress;

  const seo = createSEO({ ...pressSeo }, props);

  return (
    <WebsiteLayout>
      <SEO {...seo} />
      <SectionLayout backgroundColor="white" contentBelowSection>
        <div>
          <Row className="section-press-details-titles" type="flex">
            <Col lg={16} md={16} sm={16} xs={24}>
              <h1 className="text-darkest mb-5">{postTitle}</h1>
              <div className="mb-[30px] xl-max:mb-0">
                <span className="uppercase text-grey-8 text-[13px] tracking-[-0.05em] mr-5">
                  {postDate}
                </span>
              </div>
            </Col>
          </Row>
          <Row className="section-press-details-contents" gutter={{ md: 40, sm: 40 }} type="flex">
            <Col lg={16} md={15} sm={24} xs={24}>
              <StyledRichText content={content} className="xl-max:mt-5" />
            </Col>
            <Col lg={8} md={9} sm={24} xs={24}>
              <SidebarV2 email demo />
            </Col>
          </Row>
        </div>
      </SectionLayout>
      <CallToActionCards
        title="Related posts"
        backgroundColor="White"
        cardType="Press"
        cardInfo={edges.filter((x) => x.node.id !== id).slice(0, 3)}
        cta={{ buttonLabel: 'See more posts', color: 'Orange', url: '/press' }}
      />
    </WebsiteLayout>
  );
};

export default PressDetailsTemplate;

export const PressDetailsTemplateQuery = graphql`
  query PressPostBySlug($slug: String!) {
    contentfulPress(seo: { pageUrl: { eq: $slug } }) {
      __typename
      updatedAt
      id
      seo {
        ...Seo
      }
      postTitle
      content {
        raw
      }
      postDate(formatString: "MMMM DD, YYYY")
    }

    allContentfulPress(sort: { fields: [createdAt], order: DESC }, limit: 4) {
      edges {
        node {
          __typename
          updatedAt
          id
          postTitle
          seo {
            ...Seo
          }
          postDate(formatString: "MMMM DD, YYYY")
        }
      }
    }
  }
`;
